import React, { useState, useEffect } from "react";
import axios from "axios";
import projectDescriptions from '../assets/projectDescriptions.json';
import "./projectfiles.css";

const ProjectFiles = () => {
  const [projects, setProjects] = useState([]);
  const [filterNames, setFilterNames] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.github.com/users/adenjonah/starred"
        );
        const publicRepos = response.data.filter((repo) => !repo.private);
        const projectsData = await Promise.all(
          publicRepos.map(async (repo) => {
            const readmeResp = await axios
              .get(repo.url + "/readme", {
                headers: { Accept: "application/vnd.github.VERSION.raw" },
              })
              .catch(() => ({ data: "No README available" }));
  
            // Incorporate descriptions from JSON based on project name
            const description = projectDescriptions[repo.name] || "No description available.";
  
            return {
              name: repo.name,
              description: description,
              updated: new Date(repo.pushed_at).toLocaleDateString(),
              link: repo.html_url,
              readme: readmeResp.data,
            };
          })
        );
  
        const filteredProjects =
          filterNames.length === 0
            ? projectsData
            : projectsData.filter((project) =>
                filterNames.some((filterName) =>
                  project.name.includes(filterName)
                )
              );
  
        setProjects(filteredProjects); // Corrected: Use filteredProjects instead of projectsData
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    };
  
    fetchProjects();
  }, [filterNames]);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const handleProjectClick = (project) => {
    setCurrentProject(project);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateFilter = (newFilters) => {
    setFilterNames(newFilters);
  };


  useEffect(() => {
    if (isModalOpen) {
      // Call renderMarkdown to ensure new markdown content is processed
      window.renderMarkdown && window.renderMarkdown();
    }
  }, [isModalOpen, currentProject]);

  return (
    <div>
      {isMinimized ? (
        <button className="MaximizeButton" onClick={handleToggle}>
          View projects
        </button>
      ) : (
        <div className="FileExplorer">
          <button className="ExitButton" onClick={handleToggle}>
            X
          </button>
          <button className="FullScreenButton">O</button>
          <div className="FileSideBar">
            <div className="sidebarfilter" onClick={() => updateFilter([])}>
              &gt; All Projects
            </div>
            <div
              className="sidebarfilter"
              onClick={() =>
                updateFilter([
                  "SUITS-23-24-LMCC",
                  "SUITS-23-24-HMD",
                  "jonahaden.org",
                ])
              }
            >
              &gt; UI Projects
            </div>
            <div
              className="sidebarfilter"
              onClick={() => updateFilter(["statwrap"])}
            >
              &gt; Packages & Open Source
            </div>
            <div
              className="sidebarfilter"
              onClick={() => updateFilter(["Poker-Bot"])}
            >
              &gt; Computation and Simulation
            </div>
          </div>
          <div className="FileTop"><h1>Projects</h1></div>
          <div className="FileSpace">
            {projects.map((project, index) => (
              <div
                key={index}
                className="File"
                onClick={() => handleProjectClick(project)}
              >
                <div className="ProjectName">{project.name}</div>
              </div>
            ))}
          </div>
          <div className="FileBottom">
            <button className="ThumbnailsButton">H</button>
            <button className="ListButton">L</button>
            <div className="ProjectCount">{projects.length} items |</div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="Modal">
          <div className="ModalContent">
            <div className="LeftPage">
              <h2 className="ModalProjectName">{currentProject?.name}</h2>
              <p className="ProjectDescription">
                {currentProject?.description}
              </p>
              <p className="lastUpdated">Last updated: {currentProject?.updated}</p>
            </div>
            <div className="RightPage">
              <span className="CloseButton" onClick={handleCloseModal}>
                ×
              </span>
              <p className="ProjectLink">
                <a
                  href={currentProject?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check it out on GitHub!
                </a>
              </p>
              <github-md className="ReadmeContent">
                {currentProject?.readme}
              </github-md>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectFiles;
