import React from "react";
import "../styles/main.css";
import headshot from "../assets/Headshot_Jonah.jpg";
// import Resume from "../components/Resume.js";
import ProjectFiles from "../components/ProjectFiles.js";

const main = () => {
  return (
    <div className="Page">
      <div className="Intro">
        <div className="Headshot">
          <img
            src={headshot}
            alt="Jonah's Headshot"
            className="headshot-image"
          />
        </div>
        <h1>Jonah Aden</h1>
      </div>

      <div className="AboutMe">
        <div className="blurb">
          Hey! I'm Jonah, a rising Junior studying Computer Science, Statistics,
          and Political Science at Columbia University. Check out some of my
          projects below!
        </div>
      </div>

      <div className="Projects">
        <ProjectFiles />
      </div>
    </div>
  );
};

export default main;
